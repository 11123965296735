import React, { useState, useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import { Card, CardContent, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import EyeClose from '../../assets/fonts/eye-close.svg';
import ButtonMain from '../Onboarding/shared/ButtonMain';
import InputMain from '../Onboarding/shared/InputMain/InputMain';
import LogoBoleto from './LogoBoleto';
import LoaderMain from '../Onboarding/shared/LoaderMain';
import ConfirmacaoLogin from '../Onboarding/Login/Confirmacao/ConfirmacaoLogin';

import { signIn } from '../../api/authentication';
import { solicitarTokenMFA } from '../../api/usuario';
import { setEmailValidaToken, getConfigAmbiente } from '../../shared/services/User';

export default props => {
  // recupera localizacao no caso de perda de sessao
  const { location: { state: { from, ...restStateProps } = {} } = {}, setEscopoAcesso } = props;
  const [isPending, setPending] = useState(false);
  const [loginPending, setLoginPending] = useState(false);
  const [message, setMessage] = useState(false);
  const [valoresForm, setValoresForm] = useState({});
  const [tela, setTela] = useState('login');
  const [tokenCodUser, setTokenCodUser] = useState('');
  const [emailUser, setEmailUser] = useState('');
  const [redirecionar, setRedirecionar] = useState(false);
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const configAmbiente = getConfigAmbiente();

  const imgLogoLogin =
    configAmbiente && configAmbiente.imagens && configAmbiente.imagens.LOGO
      ? configAmbiente.imagens.LOGO
      : '';
  const corBaseCliente =
    configAmbiente && configAmbiente.cores && configAmbiente.cores.CORBASECLIENTE
      ? configAmbiente.cores.CORBASECLIENTE
      : '#666666';
  const corSecCliente =
    configAmbiente && configAmbiente.cores && configAmbiente.cores.CORSECUNDARIACLIENTE
      ? configAmbiente.cores.CORSECUNDARIACLIENTE
      : '#FFFFFF';

  useEffect(() => {
    sessionStorage.clear();
    if (restStateProps && restStateProps.msgError) {
      if (restStateProps.msgError === 'refresh_token_too_many_request') {
        setMessage('Tente novamente mais tarde');
      }
    }
  }, []);

  const handleFormSubmit = (values, form) => {
    // let his = props;
    setMessage(false);
    setValoresForm(values);
    setPending(true);
    solicitarTokenMFA(values)
      .then(retornoMFA => {
        let retMFA = retornoMFA.data;
        if (retMFA.mfa_disabled) {
          logar(values.login, values.password);
          return;
        }
        if (retMFA.email_invalidado) {
          setEmailValidaToken(values.login);
          setRedirecionar('/confirmacao');
        }
        if (retMFA.confirmacao) {
          setTela('confirmacao');
        }
        if (retMFA.token_user) {
          setTokenCodUser(retMFA.token_user);
        }
        if (retMFA.message) {
          setMessage(retMFA.message);
        }
        if (retMFA.email) {
          setEmailUser(retMFA.email);
        }
      })
      .catch(() => {
        setMessage('Ocorreu um erro interno, favor contatar o suporte');
        form.resetForm();
      })
      .finally(() => {
        setPending(false);
        form.setSubmitting(false);
      });
  };

  const logar = (loginValue = false, passwordValue = false) => {
    setLoginPending(true);
    // let his = props;
    let values = { ...valoresForm };

    if (passwordValue && loginValue) {
      values.login = loginValue;
      values.password = passwordValue;
    }
    let params = {
      login: values.login,
      password: values.password,
      setEscopoAcesso
    };
    signIn(params)
      .then(retornoLogin => {
        if (typeof retornoLogin.usuario_validado != 'undefined' && !retornoLogin.usuario_validado) {
          setRedirecionar('/confirmacao');
        } else if (
          typeof retornoLogin.entrada_cnpj_onboarding != 'undefined' &&
          retornoLogin.entrada_cnpj_onboarding
        ) {
          setRedirecionar('/entrada-cnpj-onboarding');
        } else if (
          typeof retornoLogin.grupo_empresarial != 'undefined' &&
          retornoLogin.grupo_empresarial
        ) {
          setRedirecionar('/grupo-empresarial');
        } else if (
          typeof retornoLogin.jornada_onboarding != 'undefined' &&
          retornoLogin.jornada_onboarding
        ) {
          setRedirecionar('/jornada-onboarding');
        } else {
          setRedirecionar(from || '/');
        }
      })
      .catch(err => {
        setMessage(err);
      })
      .finally(() => {
        setLoginPending(false);
      });
  };

  const [valuesPassword, setValuesPassword] = React.useState({
    password: '',
    showPassword: false
  });

  const handleChangePassword = prop => event => {
    setValuesPassword({ ...valuesPassword, [prop]: event.target.value });
    setMessage(false);
  };

  const handleClickShowPassword = () => {
    setValuesPassword({
      ...valuesPassword,
      showPassword: !valuesPassword.showPassword
    });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleOnChangeInput = () => {
    setMessage(false);
  };

  return (
    <>
      <Container fixed style={{ paddingTop: '5%' }}>
        <Grid container>
          <Grid item xl={2} lg={2} md={2} sm={1} xs={12} />
          <Grid item xl={8} lg={8} md={8} sm={10} xs={12}>
            <Card variant="outlined" className="box-shadow-natural-5 border-radius-8">
              <CardContent>
                {loginPending && <LoaderMain />}
                {tela === 'login' ? (
                  <Grid container style={loginPending ? { display: 'none' } : {}}>
                    <Grid item xl={2} lg={2} md={2} sm={1} xs={1} />
                    <Grid item xl={8} lg={8} md={8} sm={10} xs={10} sx={{ pb: '60px' }}>
                      <>
                        <div
                          className="img-logo-login"
                          style={{ backgroundImage: `url(${imgLogoLogin})` }}
                          alt="Plataforma Digital"
                          title="Plataforma Digital"
                        />
                        <Typography
                          variant="h5"
                          className="texto-cor-principal texto-negrito texto-centro"
                          sx={{ margin: '0 0 20px 0' }}
                        >
                          Acessar conta
                        </Typography>
                        <Formik
                          initialValues={{
                            login: '',
                            password: ''
                          }}
                          validationSchema={Yup.object().shape({
                            login: Yup.string().required('Informe o login'),
                            password: Yup.string().required('Informe a senha')
                          })}
                          onSubmit={handleFormSubmit}
                          validateOnChange={false}
                          validateOnBlur={false}
                          render={({ isSubmitting }) => {
                            return (
                              <>
                                <Form>
                                  <Grid container>
                                    <Grid item xl={1} lg={1} md={1} sm={1} xs={12} />
                                    <Grid item xl={10} lg={10} md={10} sm={10} xs={12}>
                                      <Field
                                        type="text"
                                        name="login"
                                        titulo="Login"
                                        id="login"
                                        size="small"
                                        component={InputMain}
                                        className="w-100"
                                        onChangeInput={handleOnChangeInput}
                                        uppercase={false}
                                        autoComplete="off"
                                        disabled={isPending || isSubmitting}
                                      />

                                      <Field
                                        name="password"
                                        id="password"
                                        titulo="Senha"
                                        component={InputMain}
                                        tipoComponente="outlineInput"
                                        type={valuesPassword.showPassword ? 'text' : 'password'}
                                        value={valuesPassword.password}
                                        onChangeInput={handleChangePassword('password')}
                                        size="small"
                                        autoComplete="new-password"
                                        className="w-100"
                                        disabled={isPending || isSubmitting}
                                        endAdornment={
                                          // eslint-disable-next-line react/jsx-wrap-multilines
                                          <InputAdornment position="end">
                                            <IconButton
                                              onClick={handleClickShowPassword}
                                              onMouseDown={handleMouseDownPassword}
                                              edge="end"
                                            >
                                              {valuesPassword.showPassword ? (
                                                <Visibility />
                                              ) : (
                                                <img src={EyeClose} alt="icone olho fechado" />
                                              )}
                                            </IconButton>
                                          </InputAdornment>
                                        }
                                      />

                                      {message && (
                                        <Typography
                                          variant="subtitle1"
                                          className="texto-danger pad-10 texto-centro"
                                        >
                                          {message}
                                        </Typography>
                                      )}
                                    </Grid>
                                  </Grid>

                                  <Grid container spacing={2} className="mt-3">
                                    <Grid item xl={1} lg={1} md={1} sm={1} xs={12} />
                                    <Grid item xl={10} lg={10} md={10} sm={10} xs={12}>
                                      <ButtonMain
                                        type="submit"
                                        tipoBotao="principal"
                                        disabled={isPending || isSubmitting}
                                        sx={{
                                          backgroundColor: `${corBaseCliente} !important`,
                                          color: `${corSecCliente} !important`
                                        }}
                                      >
                                        Acessar
                                      </ButtonMain>

                                      <div className="text-center mt-4">
                                        <Link
                                          className="mt-5 link-criar-conta"
                                          disabled={isPending || isSubmitting}
                                          to="/authentication/forgot-password"
                                        >
                                          Esqueci minha senha
                                        </Link>

                                        <center className="mt-2 texto-criar-conta">
                                          {/* Não tem uma conta?&nbsp; */}
                                          {onlySmallScreen ? <br /> : ''}
                                          {/* <Link
                                            className="mt-5 link-criar-conta"
                                            disabled={isPending || isSubmitting}
                                            to="/cadastro"
                                          >
                                            Crie a sua agora.
                                          </Link> */}
                                        </center>

                                        <center>
                                          <Link
                                            className="visaoSacadoBtnNew mt-5"
                                            disabled={isPending || isSubmitting}
                                            to="/segunda-via"
                                          >
                                            <i className="img-logo-boleto">
                                              <LogoBoleto />
                                            </i>
                                            2ª via do boleto
                                          </Link>
                                        </center>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Form>
                              </>
                            );
                          }}
                        />
                      </>
                    </Grid>
                  </Grid>
                ) : tela === 'confirmacao' ? (
                  <ConfirmacaoLogin
                    logar={logar}
                    tokenCodUser={tokenCodUser}
                    emailUser={emailUser}
                    setTela={setTela}
                    setMessageLogin={setMessage}
                  />
                ) : (
                  <></>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      {redirecionar && (
        <>
          <Redirect
            to={{
              pathname:
                typeof redirecionar === 'string'
                  ? redirecionar
                  : redirecionar.pathname
                  ? redirecionar.pathname
                  : 'authentication/login',
              state: { redirecionando: 'login' }
            }}
          />
        </>
      )}
    </>
  );
};
