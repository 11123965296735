/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { Form, Formik, Field } from 'formik';
import { Select, mascararCpfCnpj } from '../../../shared';
import {
  getListarCheckpoints,
  getListarCheckpointsNegocios,
  getListarCheckpointsPropostas,
  postSalvarCheckpointsNegocios,
  postSalvarCheckpointsPropostas
} from '../../../api/dashboard';
import LoaderMain from '../../Onboarding/shared/LoaderMain';
import { formatarDataCreatedAtNow } from '../../Onboarding/shared/Utils';

import * as Yup from 'yup';
import ButtonMain from '../../Onboarding/shared/ButtonMain';

const ModalGridDashboardCheckpoints = ({
  modalGridCheckpoints,
  setModalGridCheckpoints,
  lstPropostasEsteira,
  setLstPropostasEsteira,
  lstPropostasEsteiraCompleta,
  setLstPropostasEsteiraCompleta,
  listaClientes,
  setListaClientes
}) => {
  const [isPending, setIsPending] = useState(false);
  const [dadosCheckpoints, setDadosCheckpoints] = useState([]);
  const [historicoCheckpoints, setHistoricoCheckpoints] = useState([]);
  const [valorCheckpoint, setValorCheckpoint] = useState('');
  const [inputObservacoesCheckpoints, setInputObservacoesCheckpoints] = useState('');
  const [defaultObservacoesCheckpoints, setDefaultObservacoesCheckpoints] = useState('');
  const [mensagemErro, setMensagemErro] = useState();
  const [mensagemSucesso, setMensagemSucesso] = useState();
  const [valorSelecionado, setValorSelecionado] = useState(valorCheckpoint);
  const [valueRadio, setValueRadio] = useState(false);
  const [error, setError] = useState(false);
  const [mostraMensagemErroSelecaoRadio, setMostraMensagemErroSelecaoRadio] = useState(false);
  const [restauraBotao, setRestauraBotao] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm', 'md'));

  useEffect(() => {
    if (modalGridCheckpoints && modalGridCheckpoints.cnpjCliente) {
      setIsPending(true);
      setValorSelecionado(valorCheckpoint);
      setDadosCheckpoints([]);
      getListarCheckpoints()
        .then(res => {
          if (res && res.data) {
            let dados = res.data;
            setDadosCheckpoints(
              dados.map(checkPoint => {
                return {
                  value: checkPoint.tag,
                  label: checkPoint.descricao
                };
              })
            );
          }
        })
        .finally(() => {
          setIsPending(false);
        });
    }
  }, [modalGridCheckpoints]);

  useEffect(() => {
    if (modalGridCheckpoints && modalGridCheckpoints.cnpjCliente) {
      setIsPending(true);
      setDadosCheckpoints([]);
      if (modalGridCheckpoints.ckeckpointsClientes) {
        getListarCheckpointsNegocios(modalGridCheckpoints.cnpjCliente)
          .then(res => {
            if (res && res.data && res.data.length > 0) {
              let dados = res.data;
              setHistoricoCheckpoints(dados);
              if (dados && dados.observacao !== undefined && dados.observacao !== null) {
                setDefaultObservacoesCheckpoints(dados.observacao);
              }
              setValorSelecionado(dados.tag);
            }
          })
          .finally(() => {
            setIsPending(false);
          });
      } else {
        getListarCheckpointsPropostas(modalGridCheckpoints.cnpjCliente)
          .then(res => {
            if (res && res.data && res.data.length > 0) {
              let dados = res.data;
              setHistoricoCheckpoints(dados);
              setValorCheckpoint(dados[0].tag);

              if (dados && dados.observacao !== undefined && dados.observacao !== null) {
                setDefaultObservacoesCheckpoints(dados.observacao);
              }
              setValorSelecionado(dados.tag);
            }
          })
          .finally(() => {
            setIsPending(false);
          });
      }
    }
  }, [modalGridCheckpoints]);

  const obterDescricaoCheckpointPorSuaTag = valorSelecionado => {
    if (!dadosCheckpoints || !Array.isArray(dadosCheckpoints)) return '';

    const checkpointEncontrado = dadosCheckpoints.find(
      checkpoint => checkpoint.value === valorSelecionado
    );

    return checkpointEncontrado ? checkpointEncontrado.label : '';
  };

  const salvarCheckpointsNegocios = (form, params, checkpointObservacoes, descricaoCheckpoint) => {
    postSalvarCheckpointsNegocios(params)
      .then(res => {
        if (res && res.data) {
          let dados = res.data;

          setValorCheckpoint(dados.tag);
          setInputObservacoesCheckpoints(checkpointObservacoes);

          setListaClientes(prevState =>
            prevState.map(cliente =>
              cliente.cnpjCliente === modalGridCheckpoints.cnpjCliente
                ? {
                    ...cliente,
                    checkpoint_tag: dados.tag,
                    checkpoint_descricao: descricaoCheckpoint,
                    checkpoint_observacao: checkpointObservacoes,
                    data_insercao: dados.created_at_formatted,
                    nome_usuario_insercao: dados.nome_usuario_formatted
                  }
                : cliente
            )
          );

          if (res.status === 200) {
            setMensagemSucesso(dados.mensagem);
            setTimeout(() => {
              setModalGridCheckpoints(false);
            }, 2000);
          } else {
            setModalGridCheckpoints(false);
          }
        }
      })
      .catch(err => {
        const {
          response: {
            data: { error: { mensagemErro = 'Erro ao salvar as informações' } = {} } = {}
          } = {}
        } = err;
        setMensagemErro(mensagemErro);
      })
      .finally(() => {
        form.resetForm();
        form.setSubmitting(false);
      });
  };

  const salvarCheckpointsPropostas = (form, params, checkpointObservacoes, descricaoCheckpoint) => {
    postSalvarCheckpointsPropostas(params)
      .then(res => {
        if (res && res.data) {
          let dados = res.data;

          setValorCheckpoint(dados.tag);
          setInputObservacoesCheckpoints(checkpointObservacoes);
          //setDefaultObservacoesCheckpoints(checkpointObservacoes);

          let tempLstPropostasEsteira = [...lstPropostasEsteira].map(prop => {
            if (prop.cnpjCliente === modalGridCheckpoints.cnpjCliente) {
              prop.checkpoint_tag = dados.tag;
              prop.checkpoint_descricao = descricaoCheckpoint;
              prop.checkpoint_observacao = checkpointObservacoes;
              prop.data_insercao = formatarDataCreatedAtNow();
              prop.nome_usuario_insercao = dados.nome_usuario;
            }
            return prop;
          });

          setLstPropostasEsteira(tempLstPropostasEsteira);

          let tempLstPropostasEsteiraCompleta = [...lstPropostasEsteiraCompleta].map(prop => {
            if (prop.cnpjCliente === modalGridCheckpoints.cnpjCliente) {
              prop.checkpoint_tag = dados.tag;
              prop.checkpoint_descricao = descricaoCheckpoint;
              prop.checkpoint_observacao = checkpointObservacoes;
              prop.data_insercao = formatarDataCreatedAtNow();
              prop.nome_usuario_insercao = dados.nome_usuario;
            }
            return prop;
          });

          setLstPropostasEsteiraCompleta(tempLstPropostasEsteiraCompleta);

          if (res.status === 200) {
            setMensagemSucesso(dados.mensagem);
            setTimeout(() => {
              setModalGridCheckpoints(false);
            }, 2000);
          } else {
            setModalGridCheckpoints(false);
          }
        }
      })
      .catch(err => {
        const {
          response: {
            data: { error: { mensagemErro = 'Erro ao salvar as informações' } = {} } = {}
          } = {}
        } = err;
        setMensagemErro(mensagemErro);
      })
      .finally(() => {
        form.resetForm();
        form.setSubmitting(false);
      });
  };

  const cadastrarObservacoesCheckpoint = (values, form) => {
    if (modalGridCheckpoints && modalGridCheckpoints.cnpjCliente) {
      const checkpointObservacoes = inputObservacoesCheckpoints
        ? inputObservacoesCheckpoints
        : defaultObservacoesCheckpoints;

      let descricaoCheckpoint = obterDescricaoCheckpointPorSuaTag(valorSelecionado);

      var origem =
        modalGridCheckpoints.ckeckpointsClientes !== true
          ? 'Checkpoint Propostas'
          : 'Checkpoint Negócios';
      let params = {
        cnpj: modalGridCheckpoints.cnpjCliente,
        tag: valorSelecionado,
        observacao: checkpointObservacoes,
        origem: origem
      };

      setIsPending(true);
      setDadosCheckpoints([]);
      if (modalGridCheckpoints.ckeckpointsClientes) {
        salvarCheckpointsNegocios(form, params, checkpointObservacoes, descricaoCheckpoint);
      } else {
        salvarCheckpointsPropostas(form, params, checkpointObservacoes, descricaoCheckpoint);
      }
    }
  };

  const handleChangeTypingObservacaoCheckpoint = event => {
    setInputObservacoesCheckpoints(event.target.value);
    setDefaultObservacoesCheckpoints(event.target.value);
  };

  const handleChange = event => {
    setValorCheckpoint(event.value);
    setValorSelecionado(event.value);
  };

  const validationSchema = Yup.object().shape({
    tag_checkpoint: Yup.string()
      .required('Selecione uma opção de checkpoint.')
      .nullable(),
    observacoesCheckpoints: Yup.string().max(10000, 'Máximo de 10.000 caracteres.')
  });

  return (
    <Dialog
      open={modalGridCheckpoints !== false}
      fullWidth={true}
      maxWidth="md"
      keepMounted
      onClose={() => setModalGridCheckpoints(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="texto-cor-principal texto-negrito">
        <Box className="items-centralizados-esquerda borda-bottom-2-gray pad-topbot-10">
          {modalGridCheckpoints ? modalGridCheckpoints.modalTitle : ''}
          <IconButton
            onClick={() => setModalGridCheckpoints(false)}
            className="float-right margin-neg-top-5"
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {modalGridCheckpoints ? (
          <Box
            className="box-checkpoint"
            sx={isPending ? { position: 'relative', height: '250px' } : {}}
          >
            {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
            <Box className="pad-5 sub-titulo border-radius-8 margin-top-10">
              <Typography
                variant="subtitle2"
                className="font-size-1-1-rem texto-cor-cinza-escuro font-bold"
              >
                {modalGridCheckpoints.nomeCliente} {mascararCpfCnpj(modalGridCheckpoints.cnpjCliente)}
              </Typography>
            </Box>
            <Grid
              className="margin-top-25 borda-bottom-2-main"
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            />
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="margin-top-30">
              <Formik
                initialValues={{
                  tag_checkpoint: valorCheckpoint || '',
                  observacoesCheckpoints: inputObservacoesCheckpoints
                }}
                validationSchema={validationSchema}
                onSubmit={(values, form) => {
                  if (error) {
                    setMostraMensagemErroSelecaoRadio(true);
                  } else {
                    cadastrarObservacoesCheckpoint(values, form);
                  }
                }}
              >
                {({ submitForm, isSubmitting, errors, touched }) => (
                  <Form className="formCheckpoint">
                    <Grid container>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ padding: '2px' }}>
                        <Grid>
                          <Field
                            id="tag_checkpoint"
                            name="tag_checkpoint"
                            className="select-dashBoard"
                            options={dadosCheckpoints}
                            value={valorCheckpoint || ''}
                            onChange={handleChange}
                            component={Select}
                            placeholder="Selecione uma opção"
                            autoComplete="off"
                            isClearable={false}
                          />
                        </Grid>
                        <Grid container>
                          <TextField
                            defaultValue={defaultObservacoesCheckpoints}
                            onChange={handleChangeTypingObservacaoCheckpoint}
                            type="text"
                            className="fixed-input-checkpoint"
                            placeholder="Adicione informações sobre o checkpoint"
                            fullWidth
                            name="observacoesCheckpoints"
                            id="observacoes_checkpoints"
                            autoComplete="off"
                            disabled={isSubmitting && mostraMensagemErroSelecaoRadio && !restauraBotao}
                            multiline
                            rows={3}
                            inputProps={{ maxLength: 10000 }}
                          />
                        </Grid>
                      </Grid>
                      <>
                        {mensagemSucesso && (
                          <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert severity="success">{mensagemSucesso}</Alert>
                          </Stack>
                        )}
                      </>
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        container
                        direction="row"
                        sx={{
                          justifyContent: 'flex-end',
                          alignItems: 'center'
                        }}
                      >
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          gap={1.5}
                          sx={{ width: 'auto', mt: 2 }}
                        >
                          <ButtonMain
                            tipoBotao="transparente"
                            onClick={() => setModalGridCheckpoints(false)}
                          >
                            Cancelar
                          </ButtonMain>
                          <ButtonMain
                            tipoBotao="azul-escuro"
                            onClick={submitForm}
                            disabled={isSubmitting}
                          >
                            Salvar
                          </ButtonMain>
                        </Box>
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Box mt={4} className="historico-container">
                          <Typography variant="h6" className="historico-title">
                            Histórico
                          </Typography>
                          <Box className="historico-box">
                            {historicoCheckpoints.map((item, index) => (
                              <Box key={index} mb={3} className="checkPoint">
                                <Box
                                  className="checkPoint-header"
                                  display="flex"
                                  flexDirection={isSmallScreen ? 'column' : 'row'}
                                  alignItems={isSmallScreen ? 'flex-start' : 'center'}
                                  justifyContent="space-between"
                                >
                                  <Chip label={item.checkpoint.descricao} className="chip" />
                                  <Box
                                    display="flex"
                                    flexDirection={isSmallScreen ? 'column' : 'row'}
                                    alignItems={isSmallScreen ? 'flex-start' : 'center'}
                                    mt={isSmallScreen ? 1 : 0}
                                  >
                                    <Typography className="corCinzaEscuro" mr={isSmallScreen ? 0 : 2}>
                                      {item.nome_usuario_formatted}
                                    </Typography>
                                    <Typography className="corCinzaClaro">
                                      {item.created_at_formatted}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Typography className="checkPoint-description">
                                  {item.observacao ? item.observacao : 'Nenhuma descrição encontrada'}
                                </Typography>
                                {index !== historicoCheckpoints.length - 1 && (
                                  <Divider className="checkPoint-divider" />
                                )}
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </Grid>

                      {isSubmitting && <LinearProgress />}
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Box>
        ) : (
          ''
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalGridDashboardCheckpoints;
