import React, { useEffect, useState } from 'react';
import swal from '@sweetalert/with-react';
import { Autocomplete, Card, CardContent, Grid, TextField, Typography } from '@mui/material';
import { Search, KeyboardArrowDown } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import {
  listaFiltradaGerentesPlataforma,
  listaFiltradaUnidadeNegocioPlataforma,
  listarPlataformaEListarGerentes,
  normalizaListaGerentesPlataforma,
  normalizaListaUnidadesNegociosPlataforma,
  setCodGerenteEscolhido,
  setCodUnidadeEscolhido,
  setCodPlataformaEscolhida,
  setCodVerticalEscolhida
} from '../../shared';
import PlataformasEnums from '../../shared/Enums/PlataformasEnums';
import ButtonMain from '../Onboarding/shared/ButtonMain';
import copyToClipboard from '../../utils/copyToClipboard';
import { nivelUsuarioEnum } from '../../shared/Enums';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FiltroDashboard = ({
  setFiltroDashboard,
  dadosUser,
  filterIsPending,
  setFilterIsPending,
  telaLoading,
  loadingDashFim,
  setPlatafUser,
  escopoAcesso
}) => {
  const { pathname } = useLocation();
  const [vertical, setVertical] = useState(0);
  const [lstVerticais, setLstVerticais] = useState([]);
  const [plataforma, setPlataforma] = useState([]);
  const [lstPlataformas, setLstPlataformas] = useState([]);
  const [lstPlataformasFiltradas, setLstPlataformasFiltradas] = useState([]);
  const [lstFiltradaGerentePlataforma, setLstFiltradaGerentePlataforma] = useState([]);
  const [gerente, setGerente] = useState(null);

  const [lstFiltradaUnidadeNegocio, setLstFiltradaUnidadeNegocio] = useState([]);
  const [unidadeNegocio, setUnidadeNegocio] = useState(null);

  const desabilitarPlataforma = [nivelUsuarioEnum.GERENTE_NEGOCIOS, nivelUsuarioEnum.GERENTE_PLATAFORMA];
  const desabilitarGerente = [nivelUsuarioEnum.GERENTE_NEGOCIOS];
  const [plataformaFiltradaParaPermitirGerarLink, setPlataformaFiltradaParaPermitirGerarLink] = useState(
    true
  );
  // const [valorPadrao, setValorPadrao] = useState(24);
  // COMENTADO POIS NÂO ESTA EM USO A CONSTANTE CRIADA, E ASSIM OCORRE ERRO NO ESLINT

  useEffect(() => {
    listarPlataformaEListarGerentes({
      setLstPlataformas: setLstPlataformas,
      setPlataforma: setPlataforma,
      setLstPlataformasFiltradas: setLstPlataformasFiltradas,
      setListaFiltradaGerentes: setLstFiltradaGerentePlataforma,
      setGerenteEscolhido: setGerente,
      setListaFiltradaUnidadeNegocio: setLstFiltradaUnidadeNegocio,
      setUnidadeNegocioEscolhido: setUnidadeNegocio,
      setPending: setFilterIsPending,
      setPlatafUser: setPlatafUser,
      setLstVerticais: setLstVerticais,
      setVertical: setVertical
      // setValorPadrao: setValorPadrao,
    });
  }, []);

  useEffect(() => {
    if (filterIsPending) return false;
    // PARA GNS (níveis em que o filtro gerente esta desabilitado) CARREGA TUDO AO ABRIR O DASHBOARD E NAO APENAS A CARGA INICIAL,
    // PARA OS DEMAIS USUÁRIOS APENAS A CARGA INICIAL É CARREGADA AO ABRIR, O RESTANTE DOS GRÁFICOS É NECESSÁRIO FILTRAR
    if (desabilitarGerente.includes(dadosUser.nivel) === false) {
      handleSubmitFiltro('carga-inicial');
    } else {
      handleSubmitFiltro();
    }
  }, [filterIsPending]);

  const handleOnChangeVertical = (ev, newValue) => {
    if (newValue === undefined) return false;
    let codVertical = newValue.value;
    setVertical(codVertical);
    let plataformas = [...lstPlataformas].filter(
      plataf => parseInt(plataf.codVertical) === parseInt(codVertical)
    );

    setLstPlataformasFiltradas(plataformas);
    handleOnChangePlataforma(false, plataformas[0]);
  };

  const handleOnChangePlataforma = (ev, newValue) => {
    if (newValue === undefined) return false;

    let plataf = [];
    if (newValue.length == 0) {
      return false;
    }

    if (Array.isArray(newValue)) {
      plataf = newValue.map(element => {
        return element.value;
      });
    } else {
      plataf = [newValue.value];
    }
    setPlataforma(plataf);

    permitirCopiarLinkCadastro(plataf);

    let lstFiltradaUnidadeNegocioPlataforma = listaFiltradaUnidadeNegocioPlataforma(plataf);

    let unidadeNegocioEscolhido = null;
    if (
      lstFiltradaUnidadeNegocioPlataforma.length > 0 ||
      Object.values(lstFiltradaUnidadeNegocioPlataforma).length > 0
    ) {
      lstFiltradaUnidadeNegocioPlataforma = normalizaListaUnidadesNegociosPlataforma(
        lstFiltradaUnidadeNegocioPlataforma
      );

      setLstFiltradaUnidadeNegocio(lstFiltradaUnidadeNegocioPlataforma);
      unidadeNegocioEscolhido =
        lstFiltradaUnidadeNegocioPlataforma &&
        lstFiltradaUnidadeNegocioPlataforma[0] &&
        lstFiltradaUnidadeNegocioPlataforma[0].value
          ? lstFiltradaUnidadeNegocioPlataforma[0].value
          : null;
    }
    setUnidadeNegocio(unidadeNegocioEscolhido);
    let lstFiltradaGerentes = listaFiltradaGerentesPlataforma(plataf, unidadeNegocioEscolhido);
    lstFiltradaGerentes = normalizaListaGerentesPlataforma(lstFiltradaGerentes);
    setLstFiltradaGerentePlataforma(lstFiltradaGerentes);

    if (plataf === PlataformasEnums.PLATAFORMA_TOAZUL) {
      setGerente('todos');
    } else {
      setGerente(
        lstFiltradaGerentes && lstFiltradaGerentes[0] && lstFiltradaGerentes[0].value
          ? lstFiltradaGerentes[0].value
          : null
      );
    }
  };

  const handleOnChangeGerente = (ev, newValue) => {
    let codGerente = newValue.value;
    setGerente(codGerente);
  };

  const handleOnChangeUnidadeNegocio = (ev, newValue) => {
    let codUnidadeNegocio = newValue.value;
    setUnidadeNegocio(codUnidadeNegocio);
    let lstFiltradaGerentes = listaFiltradaGerentesPlataforma(plataforma, codUnidadeNegocio);
    lstFiltradaGerentes = normalizaListaGerentesPlataforma(lstFiltradaGerentes);

    setLstFiltradaGerentePlataforma(lstFiltradaGerentes);

    setGerente(
      lstFiltradaGerentes && lstFiltradaGerentes[0] && lstFiltradaGerentes[0].value
        ? lstFiltradaGerentes[0].value
        : null
    );
  };

  const handleSubmitFiltro = tipoFiltrar => {
    var plataf = [];
    var temTodas = false;
    plataf = plataforma.map(element => {
      if (element === 'todas') {
        temTodas = true;
      }
      return element;
    });
    if (temTodas) {
      plataf = ['todas'];
    }

    setCodVerticalEscolhida(vertical);
    setCodPlataformaEscolhida(plataf);
    setCodUnidadeEscolhido(unidadeNegocio);
    setCodGerenteEscolhido(gerente);

    let filtrado = {
      vertical: parseInt(vertical),
      plataforma: temTodas ? 'todas' : plataf,
      gerente: gerente,
      unidadeNegocio: unidadeNegocio
    };

    if (tipoFiltrar && tipoFiltrar === 'carga-inicial') filtrado.filtrar = 'carga-inicial';

    if (filtrado.plataforma && filtrado.gerente) {
      loadingDashFim('', true);
      setFiltroDashboard(filtrado);
    }
  };

  const permitirCopiarLinkCadastro = plataforma => {
    if (Array.isArray(plataforma)) {
      if (plataforma.length > 1) {
        setPlataformaFiltradaParaPermitirGerarLink(false);
      } else if (plataforma.length === 1 || isNaN(plataforma)) {
        setPlataformaFiltradaParaPermitirGerarLink(true);
      }
    }
  };

  const copiarLinkCadastro = tipoCadastro => {
    var plataf = lstPlataformas.find(option => option.value === plataforma[0]);
    var link = window.location.href.replace(pathname, '');
    link += '/cadastro/' + tipoCadastro;
    link += '/' + (dadosUser.codusuarioMD5 ? dadosUser.codusuarioMD5 : 0);
    link += '/' + plataf.valueMD5;
    if (unidadeNegocio && unidadeNegocio !== 'todos') {
      link += '/' + lstFiltradaUnidadeNegocio.find(option => option.value === unidadeNegocio).valueMD5;
    } else if (gerente && gerente !== 'todos') {
      link += '/0';
    }
    if (gerente && gerente !== 'todos' && tipoCadastro === 'cli') {
      link += '/' + lstFiltradaGerentePlataforma.find(option => option.value === gerente).valueMD5;
    }
    copyToClipboard(link);
    swal({
      title: 'Link trackeado ' + (tipoCadastro === 'brk' ? 'gerente' : 'cliente'),
      icon: 'success',
      text:
        'Link trackeado de cadastro para ' +
        (tipoCadastro === 'brk' ? 'gerente' : 'cliente') +
        (tipoCadastro === 'cli' && gerente && gerente !== 'todos' ? ' (com gerente atribuído) ' : '') +
        ' copiado com sucesso!',
      className: 'alert-trocar-empresa'
    });
  };

  return (
    <Card className="card-plataforma">
      <CardContent className="fundo-cor-azul-branco w-100-important controle-combo-overlay-24">
        <Grid container spacing={3}>
          <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
            <Typography variant="subtitle1" className="texto-cor-cinza-escuro label-padding font-bold">
              Vertical de Negócio
            </Typography>
            {lstVerticais && lstVerticais.length > 0 ? (
              <Autocomplete
                value={
                  lstVerticais && vertical
                    ? lstVerticais.find(option => option.value === vertical)
                    : null
                }
                disablePortal
                id="vertical"
                disableClearable
                onChange={handleOnChangeVertical}
                className="combobox_principal disabled-is-label"
                size="small"
                popupIcon={
                  <KeyboardArrowDown className="texto-cor-complementar w-1-5em font-size-2rem" />
                }
                options={lstVerticais || []}
                autoHighlight
                renderInput={params => <TextField {...params} label="" />}
                noOptionsText="Nenhuma registro encontrado"
                disabled={
                  filterIsPending ||
                  telaLoading ||
                  (dadosUser && desabilitarPlataforma.includes(dadosUser.nivel)) ||
                  lstVerticais.length === 1
                }
              />
            ) : (
              <div className="error pad-15">
                <h2>
                  Nenhuma vertical encontrada, favor contatar o suporte para a configuração do seu
                  cadastro
                </h2>
              </div>
            )}
          </Grid>
          <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
            <Typography variant="subtitle1" className="texto-cor-cinza-escuro label-padding font-bold">
              Plataforma
            </Typography>
            {lstPlataformasFiltradas.length > 0 && plataforma.length > 0 ? (
              <Autocomplete
                title="Selecione uma ou múltiplas plataformas"
                value={
                  lstPlataformasFiltradas && plataforma
                    ? lstPlataformasFiltradas.filter(option =>
                        plataforma.some(plat => plat === option.value)
                      )
                    : []
                }
                freeSolo
                disablePortal
                //filterSelectedOptions
                disableClearable
                onChange={handleOnChangePlataforma}
                className="combobox_principal_sobreposto disabled-is-label"
                size="small"
                popupIcon={
                  <KeyboardArrowDown className="texto-cor-complementar w-1-5em font-size-2rem" />
                }
                multiple
                limitTags={1}
                id="plataforma"
                options={lstPlataformasFiltradas || []}
                getOptionLabel={option => option.label}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.label}
                  </li>
                )}
                disableCloseOnSelect
                autoHighlight
                renderInput={params => <TextField {...params} label="" />}
                noOptionsText="Nenhuma registro encontrado"
                disabled={
                  filterIsPending ||
                  telaLoading ||
                  (dadosUser && desabilitarPlataforma.includes(dadosUser.nivel))
                }
              />
            ) : (
              <div className="error pad-15">
                <h2>
                  Nenhuma plataforma encontrada, favor contatar o suporte para a configuração do seu
                  cadastro
                </h2>
              </div>
            )}
          </Grid>
          {lstFiltradaUnidadeNegocio && unidadeNegocio && (
            <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
              <Typography variant="subtitle1" className="texto-cor-cinza-escuro label-padding font-bold">
                Unidade de Negócio
              </Typography>
              <Autocomplete
                value={
                  lstFiltradaUnidadeNegocio && unidadeNegocio
                    ? lstFiltradaUnidadeNegocio.find(option => option.value === unidadeNegocio)
                    : null
                }
                disablePortal
                id="unidadeNegocio"
                disableClearable
                onChange={handleOnChangeUnidadeNegocio}
                className="combobox_principal disabled-is-label"
                size="small"
                popupIcon={
                  <KeyboardArrowDown className="texto-cor-complementar w-1-5em font-size-2rem" />
                }
                options={lstFiltradaUnidadeNegocio || []}
                autoHighlight
                renderInput={params => <TextField {...params} label="" />}
                noOptionsText="Nenhuma registro encontrado"
                disabled={
                  filterIsPending ||
                  telaLoading ||
                  (dadosUser && desabilitarGerente.includes(dadosUser.nivel))
                }
              />
            </Grid>
          )}
          {lstFiltradaGerentePlataforma && gerente && (
            <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
              <Typography variant="subtitle1" className="texto-cor-cinza-escuro label-padding font-bold">
                Gerente/SDR
              </Typography>
              <Autocomplete
                value={
                  lstFiltradaGerentePlataforma && gerente
                    ? lstFiltradaGerentePlataforma.find(option => option.value === gerente)
                    : null
                }
                disablePortal
                id="gerente"
                disableClearable
                onChange={handleOnChangeGerente}
                className="combobox_principal disabled-is-label"
                size="small"
                popupIcon={
                  <KeyboardArrowDown className="texto-cor-complementar w-1-5em font-size-2rem" />
                }
                options={lstFiltradaGerentePlataforma || []}
                autoHighlight
                renderInput={params => <TextField {...params} label="" />}
                noOptionsText="Nenhuma registro encontrado"
                disabled={
                  filterIsPending ||
                  telaLoading ||
                  (dadosUser && desabilitarGerente.includes(dadosUser.nivel)) ||
                  (plataforma.includes(PlataformasEnums.PLATAFORMA_TOAZUL) && gerente === 'todos')
                }
              />
            </Grid>
          )}
          <Grid item xl={1} lg={1} md={12} sm={12} xs={12}>
            <ButtonMain
              type="button"
              tipoBotao="transparente-com-bordas-principal"
              disabled={filterIsPending || telaLoading}
              onClick={() => handleSubmitFiltro()}
              className="font-size-0-875rem"
              sx={{ mt: '38px' }}
            >
              Filtrar
              <Search />
            </ButtonMain>
          </Grid>
        </Grid>

        {escopoAcesso &&
          escopoAcesso.componente &&
          (escopoAcesso.componente['dashboard-geral-link-trackeado-cliente'] ||
            escopoAcesso.componente['dashboard-geral-link-trackeado-gerente']) &&
          plataforma !== 'todas' &&
          unidadeNegocio !== 'todas' && (
            <>
              <Typography
                variant="subtitle1"
                className="texto-cor-cinza-escuro label-padding font-bold margin-top-10"
              >
                Links Trackeados
              </Typography>
              <Grid container spacing={3}>
                {escopoAcesso.componente['dashboard-geral-link-trackeado-gerente'] && (
                  <Grid item xl={3} lg={4} md={5} sm={6} xs={12}>
                    <ButtonMain
                      type="button"
                      tipoBotao="azul-escuro"
                      disabled={
                        filterIsPending || telaLoading || !plataformaFiltradaParaPermitirGerarLink
                      }
                      onClick={() => copiarLinkCadastro('brk')}
                      className="font-size-0-875rem"
                    >
                      Obter Link de Cadastro para Gerente/SDR
                    </ButtonMain>
                  </Grid>
                )}
                {gerente &&
                  gerente !== 'todos' &&
                  escopoAcesso.componente['dashboard-geral-link-trackeado-cliente'] && (
                    <Grid item xl={3} lg={4} md={5} sm={6} xs={12}>
                      <ButtonMain
                        type="button"
                        tipoBotao="azul-escuro"
                        disabled={
                          filterIsPending || telaLoading || !plataformaFiltradaParaPermitirGerarLink
                        }
                        onClick={() => copiarLinkCadastro('cli')}
                        className="font-size-0-875rem"
                      >
                        Obter Link de Cadastro para Cliente
                      </ButtonMain>
                    </Grid>
                  )}
              </Grid>
            </>
          )}
      </CardContent>
    </Card>
  );
};
export default FiltroDashboard;
