import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router-dom';
import LoaderMain from '../Onboarding/shared/LoaderMain';
import MainOnlyHeader from '../Main/MainOnlyHeader';
import { signOut } from '../../api/authentication';

export default () => {
  const [redirecionar, setRedirecionar] = useState(false);
  const history = useHistory();
  const rotaLogin = '/authentication/login';

  useEffect(() => {
    const url = new URL(window.location.href);
    const hash = url.hash;
    const queryParams = new URLSearchParams(hash.split('?')[1]);
    const msgError = queryParams.get('msg_error');
    signOut().then(() => {
      if (msgError) {
        setRedirecionar({ redirecionar_login: true, msgError });
      } else {
        history.push(rotaLogin);
      }
    });
  }, []);

  return (
    <MainOnlyHeader>
      <LoaderMain />
      {redirecionar && redirecionar.redirecionar_login && (
        <>
          <Redirect
            to={{
              pathname: rotaLogin,
              state: { msgError: redirecionar.msgError }
            }}
          />
        </>
      )}
    </MainOnlyHeader>
  );
};
